import { Controller } from '@hotwired/stimulus';
import Splide from '@splidejs/splide';

export default class extends Controller {
    static targets = ['modal'];
    static values = {
        index: String,
        carouselId: String,
    };

    splide;

    open(event) {
        this.modalTarget.style.display = 'flex';

        if (this.splide == null) {
            this.splide = new Splide(`#${this.carouselIdValue}`, {
                perPage: 1,
                perMove: 1,
                start: 0,
                rewind: true,
                rewindByDrag: true,
                pagination: true,
                arrows: false,
                height: '80vh',
                classes: {
                    page: 'splide__pagination__page bg-content-quiet',
                },
            });
            this.splide.on('ready', () => {
                this.modalTarget.focus();
                this.splide.go(event?.params?.index - 1 ?? 0);
            });
            this.splide.mount();
        } else {
            this.modalTarget.focus();
            if (event.params.index) {
                this.splide.go(event.params.index - 1);
            }
        }
    }

    close(event) {
        // close modal on click oustide picture
        if (
            event.target.classList.contains('lightbox-modal') ||
            event.target.classList.contains('lightbox-modal__close')
        ) {
            this.modalTarget.style.display = 'none';
        }
    }

    next() {
        this.splide.go('>');
    }

    prev() {
        this.splide.go('<');
    }
}
