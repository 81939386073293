import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    intlTelInput;
    inputFullField;

    static targets = ['input'];

    loadIntelInput() {
        this.intlTelInput = intlTelInput(this.inputTarget, {
            autoHideDialCode: false,
            autoPlaceholder: 'off',
            initialCountry: 'fr',
            preferredCountries: [
                'fr',
                'be',
                'ch',
                'de',
                'gb',
                'es',
                'nl',
                'pt',
                'us',
            ],
            separateDialCode: true,
        });

        // Add listeners once the library has been loaded.
        this.intlTelInput.promise.then(() => {
            this.inputTarget.addEventListener('countrychange', () =>
                this.formatInputValue(),
            );
            this.inputTarget.addEventListener('change', () =>
                this.formatInputValue(),
            );
            this.formatInputValue();
        });
    }

    initialize() {
        this.insertFullField();

        // lazy load utils only when not available
        if (!window.intlTelInputUtils) {
            import('../js/legacy/front/js/dep/intlTelInput-utils').then(() => {
                this.loadIntelInput();
            });
        }
    }

    formatInputValue() {
        if (!this.intlTelInput) {
            return;
        }

        const country = this.intlTelInput.getSelectedCountryData();

        // Format number (International format without prefix)
        this.inputTarget.value = this.intlTelInput
            .getNumber(intlTelInputUtils.numberFormat.INTERNATIONAL)
            .replace(new RegExp('^(\\+|00)' + country.dialCode), '')
            .trim();

        // Update full number (E164 format)
        this.inputFullField.value = this.intlTelInput.getNumber(
            intlTelInputUtils.numberFormat.E164,
        );
    }

    insertFullField() {
        this.inputFullField = document.createElement('input');
        this.inputFullField.setAttribute('type', 'hidden');
        this.inputFullField.name = `${this.inputTarget.name}_full`;
        this.inputTarget.after(this.inputFullField);
    }
}
