import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['payments', 'modal', 'button'];

    paymentIds = [];

    check(event) {
        if (event.currentTarget.checked) {
            this.paymentIds.push(event.currentTarget.dataset.id);
        } else {
            this.paymentIds.splice(
                this.paymentIds.indexOf(event.currentTarget.dataset.id),
                1,
            );
        }
        this.updateButton();
    }

    checkAll(event) {
        const isChecked = event.currentTarget.checked;

        this.paymentsTargets.forEach((payment) => {
            payment.checked = isChecked;
        });

        if (isChecked) {
            this.paymentIds = this.paymentsTargets.map(
                (payment) => payment.dataset.id,
            );
        } else {
            this.paymentIds = [];
        }

        this.updateButton();
    }

    updateButton() {
        if (this.paymentIds.length > 0) {
            this.buttonTarget.disabled = false;
            this.buttonTarget.className +=
                'cursor-pointer bg-secondary-lighter hover:bg-dkt hover:text-white transition ease-in-out delay-150';
        } else {
            this.buttonTarget.disabled = true;
            this.buttonTarget.className =
                'py-2 px-4 rounded font-bold text-tertiary text-base bg-gray-300';
        }
    }

    display() {
        this.clearModal();
        this.modalTarget.classList.remove('hidden');
    }

    hide(event) {
        this.modalTarget.classList.add('hidden');
    }

    clearModal() {
        this.modalTarget.querySelector('input').value = '';
        this.modalTarget.querySelector('#inputErrorMessage').innerHTML = '';
    }

    submit(event) {
        event.preventDefault();
        let transferLabel = event.target.querySelector('input').value;

        const formData = new FormData();
        formData.append('transferLabel', transferLabel);
        this.paymentIds.forEach((paymentId) => {
            formData.append('paymentIds[]', paymentId);
        });

        fetch(this.modalTarget.dataset.url, {
            method: 'POST',
            body: formData,
        })
            .then(async (response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    let msg = await response.json();
                    throw new Error(msg);
                }
            })
            .then((data) => {
                window.location.reload();
            })
            .catch((error) => {
                let errorMessages = error.message.split(',');
                errorMessages.forEach((msg) => {
                    let node = document.createElement('li');
                    let textNode = document.createTextNode(msg);
                    node.appendChild(textNode);
                    event.target
                        .querySelector('#inputErrorMessage')
                        .appendChild(node);
                });
            });
    }
}
