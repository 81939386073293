import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['container', 'modalContainer'];

    static values = {
        url: String,
    };

    connect() {
        this.fetchReviews(this.urlValue, this.containerTarget);
    }

    changePage(event) {
        this.fetchReviews(
            event.currentTarget.dataset.href,
            this.modalContainerTarget,
        );
        document
            .getElementById('reviews-modal')
            .scrollTo({ top: 0, behavior: 'smooth' });
    }

    async fetchReviews(url, container) {
        const response = await fetch(url, {
            method: 'GET',
            Headers: {
                Accept: 'application.json',
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok || 204 === response.status) {
            container.parentElement.remove();
            return;
        }

        container.innerHTML = await response.text();
    }
}
