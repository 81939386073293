import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['button'];

    submit() {
        if (this.hasButtonTarget) {
            this.buttonTarget.innerHTML = `<span class="inline-block h-m w-m animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]">
                    <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
                </span>`;
            this.buttonTarget.disabled = true;
        }
    }
}
